// Do not delete, very important for CS2 match debugging
// import "@/game-csgo/cs2-event-replayer.mjs";

import { isPersistent, readState } from "@/__main__/app-state.mjs";
import blitzMessage, {
  EVENTS,
  handleMessage,
  initEvents,
} from "@/__main__/ipc-core.mjs";
import mainRefs from "@/__main__/refs.mjs";
import { setRoute } from "@/__main__/router.mjs";
import { initSettings, resetLiveGame } from "@/app/actions.mjs";
import type {
  MatchStart,
  PhaseChanged,
  Player,
  RoundEnd,
  ViewBlitzApp,
} from "@/data-models/csgo-game-events.mjs";
import {
  matchStartValidator,
  phaseChangedValidator,
  playerValidator,
  roundEndValidator,
  viewBlitzAppValidator,
} from "@/data-models/csgo-game-events.mjs";
import {
  addMatchId,
  addOrUpdateRound,
  matchEnd,
  // matchEndOverlayBenchmarking,
  setOrUpdateLiveGame,
  sideEffects,
  updateLoggedInAccountId,
  updateLoggedInProfile,
} from "@/game-csgo/actions.mjs";
import { GAME_PHASES } from "@/game-csgo/constants.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition.mjs";
import {
  isBombDefusalMode,
  isDeathMatch,
  isMatchAbandoned,
} from "@/game-csgo/utils.mjs";
import overlayRefs from "@/shared/OverlayContainerWithAd.refs.jsx";
import clone from "@/util/clone.mjs";
import { devDebug, devError } from "@/util/dev.mjs";
import { updateLatestPlayed } from "@/util/game-handlers.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

const overlayMatchRef: {
  preventDuplicateMatchEnd: boolean;
  _preventDupeMatchEnd: boolean;
  _timeoutId: ReturnType<typeof setTimeout>;
} = {
  preventDuplicateMatchEnd: false,
  _preventDupeMatchEnd: false,
  _timeoutId: undefined,
};
Object.defineProperty(overlayMatchRef, "preventDuplicateMatchEnd", {
  get() {
    const result = overlayMatchRef._preventDupeMatchEnd;
    if (result) devDebug("Received prevented excessive event");
    return result;
  },
  set() {
    if (overlayMatchRef._timeoutId) return;
    overlayMatchRef._preventDupeMatchEnd = true;
    overlayMatchRef._timeoutId = setTimeout(() => {
      overlayMatchRef._preventDupeMatchEnd = false;
      overlayMatchRef._timeoutId = undefined;
    }, 3e3);
  },
});
// This util is to prevent match end event to be called multiple times within a timeout
const isPreventDupeMatchEnd = () => {
  if (overlayMatchRef.preventDuplicateMatchEnd) {
    devDebug("Received MATCH_END, prevented excessive match end event");
    return true;
  }
  // This will automatically return to false after a timeout
  overlayMatchRef.preventDuplicateMatchEnd = true;
  return false;
};

export const registerEventListeners = async () => {
  // Flag to prevent handling EVENTS.CSGO_PLAYER when we're in the middle of
  // a match. This flag is reset when closing the game.
  let skipHandlePlayerEvent = false;

  await initEvents;
  handleMessage(EVENTS.CSGO_MATCH_START, (matchStart: MatchStart) => {
    devDebug("Received CSGO_MATCH_START", matchStart);

    if (overlayMatchRef.preventDuplicateMatchEnd) return;

    // Shared for overlays + client
    matchStart = matchStartValidator(matchStart);
    matchStart[isPersistent] = true;
    // Set or update csgo.liveGame
    setOrUpdateLiveGame(matchStart);

    // With how frequent this event is being sent to blitz-app,
    // this is too unreliable to use for CS2 match start event, use CSGO_PHASE_CHANGED instead
    if (isRouteOverlay()) {
      // Overlay flow
    } else {
      if (!isBombDefusalMode(matchStart)) return;
      sideEffects.enter({ match: matchStart });
    }
  });

  handleMessage(EVENTS.CSGO_ROUND_END, (roundEnd: RoundEnd) => {
    devDebug("Received CSGO_ROUND_END", roundEnd);

    // Shared for overlays + client
    roundEnd = roundEndValidator(roundEnd);
    addOrUpdateRound(roundEnd);
  });

  // GAME PHASE INT DEFINITIONS -
  // https://github.com/theblitzapp/blitz-core/blob/master/src/gameHandlers/csgo/flatbuffers_csgo/game-phase.js
  handleMessage(
    EVENTS.CSGO_PHASE_CHANGED,
    async (phaseChanged: PhaseChanged) => {
      devDebug("Received CSGO_PHASE_CHANGED", phaseChanged);

      // Shared for overlays + client
      phaseChanged = phaseChangedValidator(phaseChanged);

      if (isRouteOverlay()) {
        // Overlay flow
        if (
          phaseChanged.newGamePhase === GAME_PHASES.PLAYING_STANDARD ||
          phaseChanged.newGamePhase === GAME_PHASES.PLAYING_FIRST_HALF
        ) {
          overlayRefs.destroyAd(EVENTS.CSGO_MATCH_START);
          setRoute("/cs2/overlay");
        } else if (phaseChanged.newGamePhase === GAME_PHASES.MATCH_ENDED) {
          // if (isPreventDupeMatchEnd()) return;
          // matchEndOverlayBenchmarking();
        }
        return;
      }

      // Client flow
      if (
        phaseChanged.newGamePhase === GAME_PHASES.PLAYING_STANDARD || // Casual or bot games
        phaseChanged.newGamePhase === GAME_PHASES.PLAYING_FIRST_HALF // Competitive or premier
      ) {
        devDebug(
          "Received CSGO_PHASE_CHANGED for the CLIENT match start from game lobby or match lobby",
        );
        if (readState.csgo.liveGame) return;
        // Triggers CSGO_MATCH_START, we ABSOLUTELY need this to fire, if it doesn't we're fucked
        blitzMessage(EVENTS.CSGO_READ_LIVE_GAME);
      } else if (phaseChanged.newGamePhase === GAME_PHASES.MATCH_ENDED) {
        devDebug(
          "Received CSGO_PHASE_CHANGED for the CLIENT match end from match lobby",
        );
        // This is the most accurate CSGO_MATCH_END event,
        // since this GAME_PHASE occurs only when the player completes a full match
        if (!readState.csgo.liveGame || isPreventDupeMatchEnd()) return;
        // Always generate a match id based on sorted steam ids + map
        await addMatchId();
        const match = clone(readState.csgo.liveGame);
        const steamId = readState.settings.lastLoggedInIdByGame.csgo;
        sideEffects.leave({ steamId, match }).catch((error) => {
          devError("Postmatch mutation failed from phase change!", error);
        });
        matchEnd();
        // Check if player completed a full match
        if (isDeathMatch(match) || isMatchAbandoned(match)) {
          setRoute(`/cs2/profile/${steamId}`);
        } else {
          // The player has completed a full match redirect them to postmatch
          setRoute(
            `/cs2/match/${steamId}/${match.matchId}`,
            undefined,
            undefined,
            true,
          );
        }
      }
    },
  );

  // This event is for when the match ends abruptly, kicks/leaves, do not calculate result.
  const handleMatchEnd = async () => {
    devDebug("Received CSGO_MATCH_END");

    // Shared for overlays + client
    if (isPreventDupeMatchEnd()) return;

    if (isRouteOverlay()) {
      // Overlay flow
      // matchEndOverlayBenchmarking();
    } else {
      // Client flow
      await addMatchId();
      const match = readState.csgo.liveGame;
      const steamId = readState.settings.lastLoggedInIdByGame.csgo;
      sideEffects.leave({ steamId, match }).catch((error) => {
        devError("Postmatch mutation failed from match end!", error);
      });
      matchEnd();
      // Check if player completed a full match
      // This EVENT guarantees either flow should be possible unlike MATCH_END_LOBBY which 100% guarantees a full match
      if (isMatchAbandoned(match) || isDeathMatch(match)) {
        setRoute(`/cs2/profile/${steamId}`);
      } else {
        setRoute(
          `/cs2/match/${steamId}/${match.matchId}`,
          undefined,
          undefined,
          true,
        );
      }
    }
  };
  handleMessage(EVENTS.CSGO_MATCH_END, handleMatchEnd);

  handleMessage(EVENTS.CSGO_PLAYER, async (player: Player) => {
    devDebug("Received CSGO_PLAYER", player);
    player = playerValidator(player);
    const isOverlay = isRouteOverlay();
    const { steamId } = player;
    await initSettings();
    const loggedInAccountId = readState.settings.lastLoggedInIdByGame.csgo;
    if (loggedInAccountId !== steamId) {
      updateLoggedInAccountId(steamId);
    }
    updateLoggedInProfile(steamId, player, isOverlay);

    // this event fires when the game starts and the cpp library loads,
    // but might also fire again during a live game. We only want to process
    // redirects once for every game start, so we use this flag to ignore subsequent
    // calls.
    if (skipHandlePlayerEvent) return;

    // just in case we're in the middle of a live game, don't redirect
    if (readState.csgo.liveGame) return;

    if (isOverlay) {
      // setRoute("/cs2/overlay/benchmark", `profileId=${steamId}`);
    } else if (steamId) {
      setRoute(`/cs2/profile/${steamId}`);
    }

    skipHandlePlayerEvent = true;
  });

  handleMessage(EVENTS.CSGO_IS_RUNNING, async (isRunning: boolean) => {
    if (isRunning) updateLatestPlayed(GAME_SYMBOL_CS2);

    await mainRefs.appInit;
    const loggedInAccountId = readState.settings.lastLoggedInIdByGame.csgo;
    if (isRunning && loggedInAccountId) {
      setRoute(`/cs2/profile/${loggedInAccountId}`);
    }

    if (!isRunning) {
      skipHandlePlayerEvent = false;
      setOrUpdateLiveGame(null);
      resetLiveGame();
      // setRoute("/dashboard/cs2");
    }
  });

  handleMessage(EVENTS.CSGO_VIEW_BLITZ_APP, (options: ViewBlitzApp) => {
    options = viewBlitzAppValidator(options);
    if (options.matchId) {
      setRoute(`/cs2/match/${options.profileId}/${options.matchId}`);
    } else {
      setRoute(`/cs2/profile/${options.profileId}`);
    }
  });

  handleMessage(EVENTS.CSGO_ROUND_START, () => {
    devDebug("Received CSGO_ROUND_START");
  });

  handleMessage(EVENTS.CSGO_KILL, () => {
    devDebug("Received CSGO_KILL");
  });

  /**
   * If Blitz was opened after CSGO was already running, we need to read the state
   * of the game and update the UI accordingly. This triggers blitz core to fire
   * CSGO_PLAYER and CSGO_MATCH_START events.
   */
  blitzMessage(EVENTS.CSGO_READ_LIVE_GAME);
  blitzMessage(EVENTS.CSGO_READ_STATE);
};

import { readState } from "@/__main__/app-state.mjs";
import { EVENTS, handleMessage, initEvents } from "@/__main__/ipc-core.mjs";
import { writeSettings } from "@/app/actions.mjs";
import { GAME_SHORT_NAMES } from "@/app/constants.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import { registerEventListeners } from "@/game-csgo/handle-messages.mjs";
import { OVERLAYS } from "@/library/actions.mjs";
import clone from "@/util/clone.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import diff from "@/util/diff.mjs";
import isRouteOverlay from "@/util/is-route-overlay.mjs";

async function initCsgo() {
  await initEvents;

  if (!isRouteOverlay())
    handleMessage(EVENTS.CSGO_OVERLAY_SETTINGS, (overlaySettings) => {
      const prevOverlaySettings = clone(
        readState.settings[GAME_SHORT_NAMES[GAME_SYMBOL_CS2]][OVERLAYS],
      );

      const changes = diff(prevOverlaySettings, overlaySettings);

      const nextOverlaySettings = deepMerge(prevOverlaySettings, changes);

      writeSettings(
        [GAME_SHORT_NAMES[GAME_SYMBOL_CS2], OVERLAYS],
        nextOverlaySettings,
      );
    });

  await registerEventListeners();
}

export default initCsgo;
